import { combineReducers, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { headerReducer } from '@stb-component-layout/Header/headerReducer';
import { authReducer } from './extra-reducer/auth';
import { parameterReducer } from './extra-reducer/system-parameter';
import { cityReducer } from './extra-reducer/city';
import { provinceReducer } from './extra-reducer/province';

const reducers = combineReducers({
  header: headerReducer,
  auth: authReducer,
  paramater: parameterReducer,
  city: cityReducer,
  province: provinceReducer,
});
const persistConfig = {
  key: 'stb-web-app',
  storage,
  whitelist: ['auth', 'paramater'],
};

const composeEnhancer =
  // eslint-disable-next-line no-undef
  (process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) ||
  compose;

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(persistedReducer, composeEnhancer());
const persistor = persistStore(store);

export { reducers, persistedReducer, persistor };

export default store;
