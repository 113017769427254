import moment from 'moment';

export const useDateChange = ({ formData, setFormData, onDataChange }) => {
  function onDateChange(key, date, key2, flag = true, resetError = () => {}) {
    const d1 = date;
    const d2 = formData[key2];
    const m1 = moment(d1);
    const m2 = moment(d2);
    const diff = m1.diff(m2, 'second');
    if (flag) {
      if (diff <= 0) {
        onDataChange(key, date);
      } else {
        setFormData({
          ...formData,
          [key]: date,
          [key2]: date,
        });
      }
    } else {
      if (diff > 0) {
        onDataChange(key, date);
      } else {
        setFormData({
          ...formData,
          [key]: date,
          [key2]: date,
        });
      }
    }
    typeof resetError === 'function' && resetError();
  }

  return onDateChange;
};
