import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './styles.scss';
import { MoreVertical } from '@stb-svg';
import { NavLink } from 'react-router-dom';

export default function DropdownForTable({ data, children, direction }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown direction={direction} isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={isOpen}>
        {children ? (
          children
        ) : (
          <button className="btn btn-sm">
            <img src={MoreVertical} />
          </button>
        )}
      </DropdownToggle>
      <DropdownMenu container="body" className="action-button-dropdown">
        {data.map((item, index) => {
          if (item.to) {
            return (
              <NavLink key={index} to={item.to}>
                <DropdownItem>{item.title}</DropdownItem>
              </NavLink>
            );
          } else {
            return (
              <DropdownItem key={index} onClick={item.onClick} href={item.href}>
                {item.title}
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

DropdownForTable.defaultProps = {
  data: [],
  direction: 'left',
};

DropdownForTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      onClick: PropTypes.func,
    })
  ),
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  children: PropTypes.node,
};
