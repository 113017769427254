import React from 'react';
import PropTypes from 'prop-types';

export default function Activation({ value, trueText, falseText }) {
  let className, text;
  if (value === 'Active' || value === true) {
    className = 'success-text';
    text = trueText;
  } else if (value === 'UnActive' || value === false) {
    className = 'failed-text';
    text = falseText;
  }
  return <p className={className}>{text}</p>;
}

Activation.defaultProps = {
  value: 'Active',
  trueText: 'Aktif',
  falseText: 'Tidak Aktif',
};

Activation.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  trueText: PropTypes.string,
  falseText: PropTypes.string,
};
