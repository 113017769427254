import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { DefaultProfile, LogoStbHeader } from '@stb-images';
import { useLocation, matchPath } from 'react-router-dom';
import { usePrevious } from '@stb/utils/hooks';
import { setOpenMenu } from '@stb-component-layout/Header/headerActions';
import paths from './paths';
import './styles.scss';
import { ChevronDown2, Menu, logout } from '@stb-svg';
import { DropdownForTable } from '@stb/component/cards';
import { CLEAR_PARAMETER } from '@stb/redux/extra-constants/system-parameter';
import { logOut } from '@stb/module/auth/actions';
import swal from 'sweetalert';

function Header({ setOpenMenu }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const tabNameRedux = useSelector((state) => state.header.tabName);
  const session = useSelector((state) => state.auth.session);
  const prevTabeNameRedux = usePrevious(tabNameRedux);
  const [tabName, setTabName] = useState(tabNameRedux);

  useEffect(() => {
    for (let i in paths) {
      const p = paths[i];
      const r = matchPath(location.pathname, {
        path: i,
        exact: p.exact,
      });
      if (r) {
        setTabName(paths[i].name);
        break;
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (prevTabeNameRedux) {
      setTabName(tabNameRedux);
    }
  }, [tabNameRedux]);

  const dropDownData = [
    {
      title: (
        <>
          <img src={logout} className="me-2" width={14} />
          Log out
        </>
      ),
      onClick: () => doLogOut(),
    },
  ];

  function doLogOut() {
    swal({
      title: 'Apa anda yakin ingin logout?',
      text: 'Anda akan diarahkan ke halaman login',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Batalkan',
          value: false,
          visible: true,
          className: 'btn-secondary',
          closeModal: true,
        },
        confirm: {
          text: 'Ya',
          value: true,
          visible: true,
          className: 'btn-primary',
          closeModal: true,
        },
      },
    }).then((yes) => {
      if (yes) {
        setTimeout(() => {
          dispatch(logOut());
          dispatch({
            type: CLEAR_PARAMETER,
          });
        }, 1000);
      }
    });
  }

  return (
    <div className="header">
      <div className="header-left">
        <div className="logo">
          <img src={LogoStbHeader} />
        </div>
        <h1 className="title">{tabName}</h1>
        <button className="btn btn-sm btn-menu" onClick={() => setOpenMenu()}>
          <img src={Menu} />
        </button>
      </div>
      <div className="header-right">
        <h1 className="title">{tabName}</h1>
        <DropdownForTable data={dropDownData} direction="down">
          <div className="profile">
            <h5 className="user-name">{session?.user?.name}</h5>
            <div className="profile-picture ms-2">
              <img
                src={
                  session?.user?.image_path
                    ? process.env.REACT_APP_/dev/stb/web_GO_VOLTA + session.user.image_path
                    : DefaultProfile
                }
              />
            </div>
            <img src={ChevronDown2} />
          </div>
        </DropdownForTable>
      </div>
    </div>
  );
}
const mapDispatchToProps = {
  setOpenMenu: (payload) => setOpenMenu(payload),
};

Header.propTypes = {
  setOpenMenu: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Header);
