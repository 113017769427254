import React from 'react';
import { Row, Col } from 'reactstrap';
import { InputCheckbox } from '@stb-component-input';
import PropTypes from 'prop-types';
import './styles.scss';

const DATA = [
  {
    id: '1',
    label: 'Kunci Pengguna',
    isSelected: false,
  },
  {
    id: '2',
    label: 'Pengguna Login',
    isSelected: false,
  },
  {
    id: '3',
    label: 'Pengguna Pertama',
    isSelected: false,
  },
];

export default function InputCheckBoxGroup({ className, data: dataProps, title, onChange }) {
  const [data, setData] = React.useState(dataProps);

  function onInputCheckSelected(newSelected, index) {
    const newData = data.map((d, i) => (i === index ? { ...d, isSelected: newSelected } : d));
    setData(newData);
    onChange(newData[index]);
  }

  return (
    <div className={`input-check-box-group ${className}`}>
      <h3>{title}</h3>
      {data.map((item, index) => (
        <Row key={item.id} className="mb-3">
          <Col className="d-flex flex-row align-items-center">
            <InputCheckbox
              isSelected={item.isSelected}
              onSelectedChange={(newSelected) => {
                onInputCheckSelected(newSelected, index);
              }}
              className="group-checkbox"
            />
            <div onClick={() => onInputCheckSelected(!item.isSelected, index)}>
              <label>{item.label}</label>
            </div>
          </Col>
        </Row>
      ))}
    </div>
  );
}

InputCheckBoxGroup.defaultProps = {
  className: '',
  data: DATA,
  title: 'Configuration',
  onChange: () => {},
};

InputCheckBoxGroup.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(Object),
  title: PropTypes.string,
  onChange: PropTypes.func,
};
