import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '@stb-utils/hooks';
import { Eye, EyeOff } from '@stb-svg';
import { LoadingOnText } from '@stb-component-svg';
import './styles.scss';
import { propTypes } from 'qrcode.react';

export const REGEX_PHONE = /^\+?(\d)+$/;
export const NUMERIC_REGEX = /^[0-9]*[1-9][0-9]*$/;
export const DECIMAL_REGEX = /^-?\d+\.?(\d)*$/;
export default function InputText({
  title,
  id,
  type,
  value,
  onValueChange,
  className,
  inputClassName,
  style,
  wrapped,
  disabled,
  errorText: errorTextProps,
  onKeyDown,
  isAutoComplete,
  autoCompleteData,
  autoCompleteLabelField,
  autoCompleteKeyField,
  autoCompleteDataSelected,
  autoCompleteDataCleanUp,
  autocomplateOnAfterTyped,
  autoCompleteLoading,
  maxLength,
  maxValue,
  usePropErrorRes,
  displayViewPassword,
  maxLengthCustomWarning,
  onBlur: onBlurProps,
  inputRef,
  required,
  ...rest
}) {
  const [errorText, setErrorText] = useState(errorTextProps);
  const [stateVal, setStateVal] = useState(value);
  const [isFocus, setFocused] = useState(false);
  const [typeState, setTypeState] = useState('password');
  const onAutocompleteTextType = useDebounce(afterAutocompleteType, 300);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  useEffect(() => {
    if (isAutoComplete) {
      setStateVal(value);
    }
  }, [value]);

  function afterAutocompleteType(e) {
    if (e.target.value !== '') {
      autocomplateOnAfterTyped(e);
    } else {
      autoCompleteDataCleanUp();
    }
  }

  function onChange(e) {
    const newVal = typeof e === 'string' ? e : e.target.value;
    if (newVal.length > maxLength) {
      if (maxLengthCustomWarning) {
        setErrorText(`${maxLengthCustomWarning(maxLength)}`);
        return;
      }
      setErrorText(`Jumlah maksimal karakter adalah ${maxLength}`);
      return;
    }
    if (parseInt(newVal) > maxValue) {
      setErrorText(`Jumlah maksimal adalah ${maxValue}`);
      return;
    }
    if (type === 'phone') {
      if (newVal?.length >= 2 && !REGEX_PHONE.test(newVal)) {
        setErrorText('Input harus berupa nomor telepon');
        return;
      }
    } else if (type === 'decimal') {
      if (newVal.length > 2 && !DECIMAL_REGEX.test(newVal)) {
        setErrorText('Input harus berupa angka desimal');
        return;
      }
    } else if (type === 'numeric') {
      if (newVal.length > 2 && !NUMERIC_REGEX.test(newVal)) {
        setErrorText('Input harus berupa numerik');
        return;
      }
    }
    setErrorText('');
    if (isAutoComplete) {
      setStateVal(newVal);
      onAutocompleteTextType(e);
    } else {
      onValueChange(newVal);
    }
  }

  function getErrorText() {
    return usePropErrorRes ? errorTextProps : errorText;
  }

  function getInputValue() {
    if (isAutoComplete) {
      return stateVal;
    }
    return value;
  }

  function onFocus() {
    setFocused(true);
  }

  function onBlur() {
    setTimeout(() => {
      setFocused(false);
      onBlurProps();
    }, 200);
  }

  function renderInput() {
    return (
      <div className="d-flex flex-row position-relative">
        <input
          type={displayViewPassword ? typeState : type}
          ref={inputRef}
          value={getInputValue()}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className={`${inputClassName} ${getErrorText() && 'error-input'}`}
          disabled={disabled}
          id={id}
          onFocus={onFocus}
          onBlur={onBlur}
          {...rest}
        />
        {displayViewPassword && (
          <button
            className="btn btn-view-password"
            type="button"
            id="button-addon2"
            onClick={() => {
              if (typeState === 'password') {
                setTypeState('text');
              } else {
                setTypeState('password');
              }
            }}
          >
            <img src={typeState === 'password' ? Eye : EyeOff} alt="Eye" />
          </button>
        )}
        <div className={`inputautocomplete-list ${title === '' && 'without-label'}`}>
          {
            <>
              {autoCompleteLoading && (
                <div>
                  <LoadingOnText width="20" height="20" />
                </div>
              )}
              {isAutoComplete &&
                isFocus &&
                stateVal.trim() !== '' &&
                !autoCompleteLoading &&
                (autoCompleteData.length > 0 ? (
                  autoCompleteData.map((d) => (
                    <div
                      onClick={() => {
                        autoCompleteDataSelected(d);
                      }}
                      key={d[autoCompleteKeyField]}
                    >
                      {d[autoCompleteLabelField]}
                    </div>
                  ))
                ) : (
                  <div className="no-result">No result</div>
                ))}
            </>
          }
        </div>
      </div>
    );
  }

  if (wrapped) {
    return (
      <div className={`${className} ${isAutoComplete ? 'autocomplete col-12' : ''}`} style={style}>
        {title !== '' && (
          <label htmlFor={id}>
            {title}
            {required && <span className="text-danger"> *</span>}
          </label>
        )}
        {renderInput()}
        {getErrorText() && <p className="text-danger text-error">{getErrorText()}</p>}
      </div>
    );
  }
  return renderInput();
}

InputText.defaultProps = {
  title: '',
  id: '',
  type: 'text',
  inputClassName: 'form-control',
  value: '',
  onValueChange: () => {},
  className: '',
  style: {},
  wrapped: true,
  disabled: false,
  errorText: '',
  onKeyDown: () => {},
  isAutoComplete: false,
  autoCompleteData: [],
  autoCompleteLabelField: 'name',
  autoCompleteDataSelected: () => {},
  autoCompleteDataCleanUp: () => {},
  maxLength: Infinity,
  maxValue: Infinity,
  autocomplateOnAfterTyped: () => {},
  autoCompleteKeyField: 'id',
  usePropErrorRes: false,
  autoCompleteLoading: false,
  maxLengthCustomWarning: null,
  onBlur: () => {},
  inputRef: null,
  required: false,
};

InputText.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'phone', 'decimal', 'numeric']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  inputClassName: PropTypes.string,
  wrapped: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  onKeyDown: PropTypes.func,
  isAutoComplete: PropTypes.bool,
  autoCompleteData: PropTypes.arrayOf(Object),
  autoCompleteLabelField: PropTypes.string,
  autoCompleteDataSelected: PropTypes.func,
  maxLength: PropTypes.number,
  maxValue: PropTypes.number,
  autocomplateOnAfterTyped: PropTypes.func,
  autoCompleteDataCleanUp: PropTypes.func,
  autoCompleteKeyField: PropTypes.string,
  usePropErrorRes: PropTypes.bool,
  autoCompleteLoading: PropTypes.bool,
  maxLengthCustomWarning: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.object,
  required: PropTypes.bool,
};
