import React, { useState, useEffect } from 'react';
import { Badge, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import InputText from '../InputText';
import './styles.scss';
import { Search } from '@stb/assets/svg';
import { LoadingOnText } from '@stb/component/svg';
import { useDebounce } from '@stb/utils/hooks';

export default function InputSelectSearch({
  optionsData,
  selected,
  optionLabel,
  optionValue,
  onOptionSelected,
  onOptionUnselected,
  onOptionOpen,
  className,
  disabled,
  title,
  id,
  placeholderSearch,
  placeholderOptions,
  titleOptions,
  withSearching,
  isHorizontalInput,
  errorText: errorTextProps,
  withServerSideSearch,
  isServerSideSearchLoading,
  onDebounceSearchChanged,
  ...args
}) {
  const [searchText, setSearchText] = useState('');
  const [selection, setSelection] = useState(selected);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [listOption, setListOption] = useState([]);
  const [errorText, setErrorText] = useState(errorTextProps);

  useEffect(() => {
    setListOption(optionsLabelValue(optionsData));
  }, [optionsData]);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  const optionsLabelValue = (optionsData) => {
    return optionsData.map((option) => {
      return {
        ...option,
        value: optionValue(option),
        label: optionLabel(option),
      };
    });
  };

  const onItemSelected = (item) => {
    if (item !== selection && item !== '') {
      setSelection(item);
      setListOption(
        listOption.filter((option) => {
          return option.value === item.value;
        })
      );
    }
    setSearchText('');
    onOptionSelected(item);
  };

  const searchChanged = useDebounce(onDebounceSearchChanged);

  const handleSearchChange = (val) => {
    setSearchText(val);
    if (withServerSideSearch) searchChanged(val);
  };

  const handleUnselect = () => {
    setListOption(optionsLabelValue(optionsData));
    setSelection('');
    onOptionUnselected();
  };

  const handleOptionsOpen = () => {
    setOptionsOpen((prevState) => !prevState);
    onOptionOpen();
  };

  useEffect(() => {
    if (selected === null || selected === '') {
      handleUnselect();
    }
  }, [selected]);

  return isHorizontalInput ? (
    <div className={className}>
      <Row>
        <Col className="col-md-2 col-12 align-self-center">
          {title && (
            <label htmlFor={id} className="form-label label-input-horizontal">
              {title}
            </label>
          )}
        </Col>
        <Col className="col-md-10">{renderDashboardOptions()}</Col>
      </Row>
    </div>
  ) : (
    <div className={className}>
      {title && (
        <label htmlFor={id} className="form-label">
          {title}
        </label>
      )}
      {renderDashboardOptions()}
    </div>
  );

  function renderDashboardOptions() {
    const displayOptions = listOption.filter((item) => {
      if (item.label.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }
      return false;
    });

    function renderOption(item) {
      if (item === undefined) return 'No result';
      return item.label;
    }

    return (
      <>
        <Dropdown
          isOpen={optionsOpen}
          toggle={handleOptionsOpen}
          disabled={disabled}
          direction="down"
        >
          <DropdownToggle
            className={`select-search ${errorText && 'error-input'} ${disabled && 'disabled'}`}
            caret
          >
            {selection && (
              <>
                <Badge>
                  {selection.label}
                  <button className="btn btn-close btn-close-sm ms-2" onClick={handleUnselect} />
                </Badge>
              </>
            )}
            {!selection && <text>{placeholderOptions}</text>}
          </DropdownToggle>
          <DropdownMenu className="select-search-menu" {...args}>
            {withSearching && (
              <DropdownItem text>
                <div className="search-input">
                  <img src={Search} />
                  <InputText
                    title=" "
                    placeholder={placeholderSearch}
                    onValueChange={(val) => {
                      handleSearchChange(val);
                    }}
                    value={searchText}
                  />
                </div>
              </DropdownItem>
            )}
            <DropdownItem header className="title-options">
              {titleOptions}
              {isServerSideSearchLoading && (
                <div className="d-flex">
                  <LoadingOnText width="12" height="12" />{' '}
                  <span className="ms-1 text-muted">Loading more data...</span>
                </div>
              )}
            </DropdownItem>
            {displayOptions.length > 0 ? (
              displayOptions.map((item, index) => {
                return (
                  <DropdownItem key={`option-item-${index}`} onClick={() => onItemSelected(item)}>
                    {renderOption(item)}
                  </DropdownItem>
                );
              })
            ) : (
              <DropdownItem header className="txt-no-data">
                No result
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
        {errorText && <p className="text-danger text-error mt-2">{errorText}</p>}
      </>
    );
  }
}

InputSelectSearch.defaultProps = {
  optionsData: [],
  optionLabel: 'label',
  optionValue: 'value',
  selected: '',
  onOptionSelected: () => {},
  onOptionUnselected: () => {},
  onOptionOpen: () => {},
  className: 'form-group',
  disabled: false,
  title: '',
  id: '',
  placeholderSearch: 'pencarian',
  placeholderOptions: '--- silahkan pilih ---',
  titleOptions: 'silahkan pilih',
  withSearching: true,
  isHorizontalInput: false,
  errorText: '',
  withServerSideSearch: false,
  isServerSideSearchLoading: false,
  onDebounceSearchChanged: () => {},
};

InputSelectSearch.propTypes = {
  optionsData: PropTypes.arrayOf(Object).isRequired,
  optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  optionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object), PropTypes.number]),
  onOptionSelected: PropTypes.func,
  onOptionUnselected: PropTypes.func,
  onOptionOpen: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  id: PropTypes.string,
  placeholderSearch: PropTypes.string,
  placeholderOptions: PropTypes.string,
  titleOptions: PropTypes.string,
  withSearching: PropTypes.bool,
  isHorizontalInput: PropTypes.bool,
  errorText: PropTypes.string,
  withServerSideSearch: PropTypes.bool,
  isServerSideSearchLoading: PropTypes.bool,
  onDebounceSearchChanged: PropTypes.func,
};
