import React, { useState, useEffect } from 'react';
import { useSelector, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '@stb-redux/store';
import { MainNavigation, AuthNavigation, EmbedNavigation } from '@stb-navigation';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 1m
      retry: false,
    },
  },
});

function Navigation() {
  const currentNavigation = useSelector((state) => state.auth.currentNavigation);
  const [isEmbed, setIsEmbed] = useState(false);

  useEffect(() => {
    checkEmbedLink();
  }, []);

  window.addEventListener('popstate', function () {
    checkEmbedLink();
  });

  function checkEmbedLink() {
    const hash = window.location.hash;
    if (hash.startsWith('#/embed/')) {
      setIsEmbed(true);
    } else {
      if (isEmbed === true) {
        setIsEmbed(false);
      }
    }
  }

  function renderNavigation() {
    const hash = window.location.hash;
    if (isEmbed || hash.startsWith('#/embed/')) {
      return <EmbedNavigation />;
    }
    if (currentNavigation === 'auth') {
      return <AuthNavigation />;
    } else {
      return <MainNavigation />;
    }
  }

  return renderNavigation();
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Navigation />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
