import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { Input } from 'reactstrap';
import { VoltaModal } from '@stb-component-layout';
import { Search, Filter, Plus, Trash2 } from '@stb-svg';
import { Loading } from '@stb-component-svg';
import { InputPagination } from '@stb-component-input';
import {
  useDebounce,
  usePrevious,
  useLimitTableData,
  useWindowSize,
  useDebounceAlt,
} from '@stb-utils/hooks';
import { Download } from '@stb/assets/svg/component';

/**
 * --- To Using Searching Text Filter with Debounce Searching Method---
 * set Props isDebounceSearch True
 * set Props onAfterTextTyped to make your action after debounce
 */

/**
 * --- To using Searching Text Filter with keypress Method
 * set Props isDebounceSearch false (default is false) , you can skip this props
 * set Props onTextKeyDownTyping
 */
export default function TableContent({
  columns,
  data,
  renderFilterContent,
  showFilterButton,
  addButton,
  addButtonText,
  addButtonIcon,
  renderModalBody,
  onModalButtonClick,
  paginationSize,
  onPaginationChange: onPaginationChangeProps,
  totalSize,
  addModalDisabled,
  modalTitle,
  inputPlaceholder,
  defaultSubmitButton,
  onAddButtonClicked,
  showModal,
  keyField,
  loading,
  onApplyFilterClicked,
  showApplyFilterButton,
  applyButtonTitle,
  renderFilterDefault,
  limit: limitProps,
  onAfterTextTyped,
  onTextTyping,
  onTextKeyDownTyping,
  page: pageProps,
  textSearch: textSearchProps,
  onResetFilterClicked,
  showResetSearchButton,
  onResetSearchClicked,
  useSystemParameterLimit,
  textSearchVisibility,
  showPagination,
  isDebounceSearch,
  showDownloadButton,
  onDownloadClicked,
  isDownloadLoading,
  additionalContent,
  showFilterIndicator,
}) {
  const [isShowFilter, setShowFilter] = useState(false);
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(pageProps - 1);
  const [currentPage, setCurrentPage] = useState(pageProps);
  const [textSearch, setTextSearch] = useState(textSearchProps);
  const prevPageProps = usePrevious(pageProps);
  const [limit, pLoading] = useLimitTableData(true, useSystemParameterLimit, limitProps);
  const windowSize = useWindowSize();
  const isDesktop = windowSize > 1024;

  useEffect(() => {
    const p = document.querySelector('.react-bootstrap-table-pagination');
    const p2 = document.querySelector('.react-bootstrap-table-pagination-list');
    if (p) {
      p.children.length !== 0 && p.children[0].remove();
      p.className += ' d-flex justify-content-center align-items-center';
      if (p2) {
        p2.className += ' d-flex justify-content-center';
      }
    }
  }, []);

  useEffect(() => {
    if (prevPageProps !== undefined && pageProps !== prevPageProps) {
      setPage(pageProps - 1);

      /*
        PageProps = 1 may indicate that data/pagination was reset in the parent component,
        to the currentPage state need to be returned to pageProps (supposed to be 1).
      */
      if (pageProps === 1) setCurrentPage(pageProps);
    }
  }, [pageProps, prevPageProps]);

  const startDebounce = useDebounce(afterDebounce);
  const deb = useDebounceAlt(textSearch, 500);

  // debounce text search
  useEffect(() => {
    setTextSearch(textSearchProps);
  }, [textSearchProps]);

  // debounce text search
  useEffect(() => {
    onAfterTextTyped(textSearch);
  }, [deb]);

  function onPaginationChange(i) {
    window.scrollTo({ top: 0, behavior: 'instant' });
    setPage(i);
    onPaginationChangeProps(i);
  }

  function afterDebounce(e) {
    onAfterTextTyped(e);
  }

  function afterTextTypingEvent(e) {
    startDebounce(e);
    setTextSearch(e.target.value);
  }

  function onTextKeyDown(e) {
    onTextKeyDownTyping(e);
    afterTextTypingEvent(e);
  }

  function onChangeText(e) {
    setTextSearch(e.target.value);
    onTextTyping(e);
  }

  function toggle() {
    setModal(!modal);
  }

  function setCurrentPagination(i, { totalPagination, endNumber }, flag = '') {
    setPage(i);
    onPaginationChange(i);
    if (flag === 'last') {
      setCurrentPage(totalPagination - endNumber + 1);
      return;
    } else if (flag === 'first') {
      setCurrentPage(1);
      return;
    }
    const l = endNumber + currentPage;
    if (i === l - 1) {
      setCurrentPage(currentPage + 1);
    } else if (i < currentPage && i !== 0) {
      setCurrentPage(currentPage - 1);
    }
  }

  const isLoading = loading || pLoading;

  function renderPagination() {
    if (!showPagination) return;
    return (
      <InputPagination
        data={data}
        limit={limit}
        page={page}
        paginationSize={paginationSize}
        currentPage={currentPage}
        setCurrentPagination={setCurrentPagination}
        totalSize={totalSize}
      />
    );
  }

  function renderFilterButton() {
    if (showFilterButton)
      return (
        <button
          onClick={() => setShowFilter(!isShowFilter)}
          className="btn filter-button"
          disabled={isLoading}
        >
          <img src={Filter} />
          {showFilterIndicator && <div className="filter-indicator"></div>}
        </button>
      );
  }

  function renderDownloadButton() {
    if (showDownloadButton)
      return (
        <div className="template-container">
          <button
            onClick={onDownloadClicked}
            className="btn template-btn"
            disabled={isDownloadLoading || isLoading}
          >
            <Download />
            <span className="ms-2 d-none d-md-inline fw-normal">
              {isDownloadLoading ? 'Downloading...' : 'Download'}
            </span>
          </button>
        </div>
      );
  }

  function renderAddButton() {
    return (
      <button
        onClick={() =>
          typeof onAddButtonClicked === 'function' ? onAddButtonClicked() : setModal(true)
        }
        className="btn add-button"
        disabled={isLoading}
      >
        {addButtonIcon ?? <img className="plus" src={Plus} />}
        <span>{addButtonText}</span>
      </button>
    );
  }

  function renderFilterContentDefault(context) {
    return (
      <div className="d-flex flex-column filter-content justify-content-between">
        {renderFilterContent(context)}
        {showApplyFilterButton && (
          <div className="d-flex justify-content mt-4">
            <button
              onClick={() =>
                typeof onResetFilterClicked === 'function' ? onResetFilterClicked(context) : {}
              }
              className="btn btn-secondary btn-submit flex-1 me-1"
            >
              Reset
            </button>
            <button
              onClick={() =>
                typeof onApplyFilterClicked === 'function' ? onApplyFilterClicked(context) : {}
              }
              className="btn btn-primary btn-submit flex-1 ms-1"
            >
              {applyButtonTitle}
            </button>
          </div>
        )}
      </div>
    );
  }

  function renderFilter(context) {
    if (renderFilterDefault) {
      return renderFilterContentDefault(context);
    }
    return renderFilterContent(context);
  }

  function renderSearchForm() {
    return (
      <>
        <div className="search-form-group me-2">
          <img src={Search} />
          <Input
            value={textSearch}
            onChange={onChangeText}
            {...(!isDebounceSearch && { onKeyDown: onTextKeyDown })}
            className="cari-textinput"
            placeholder={inputPlaceholder}
            disabled={isLoading && !isDebounceSearch}
          />
        </div>
        {showResetSearchButton && (
          <button
            onClick={() => {
              setTextSearch('');
              onResetSearchClicked();
            }}
            className="btn filter-button me-2"
            disabled={isLoading}
          >
            <img src={Trash2} />
          </button>
        )}
      </>
    );
  }

  const emptyDataMessage = () => {
    return 'No Data';
  };

  return (
    <div className="table-content">
      {textSearchVisibility && (
        <>
          <div className="d-flex flex-row heading align-items-center justify-content-between">
            <div className="search-and-filter">
              {renderSearchForm()}
              {renderFilterButton()}
              {renderDownloadButton()}
              {additionalContent()}
            </div>
            {isDesktop && addButton && renderAddButton()}
          </div>

          {!isDesktop && addButton && (
            <div className="flex-1 mt-2">{addButton && renderAddButton()}</div>
          )}
        </>
      )}

      {isLoading ? (
        <div className="flex-1">
          <Loading />
        </div>
      ) : (
        <div className="mt-4">
          <BootstrapTable
            bootstrap4
            bordered={false}
            wrapperClasses="volta-table"
            rowClasses="table-row"
            keyField={keyField}
            columns={columns}
            data={data.slice(0, limit)}
            headerClasses="table-heading"
            noDataIndication={emptyDataMessage}
          />
          {renderPagination()}
        </div>
      )}

      {/* <FilterSidebar
        onClosePress={() => setShowFilter(false)}
        renderFilterContent={renderFilter}
        isShow={isShowFilter}
      /> */}
      <VoltaModal
        onModalButtonClick={() => onModalButtonClick({ modal, setModal })}
        renderBody={renderFilter}
        isOpen={isShowFilter}
        toggle={() => setShowFilter(false)}
        title="Filter"
        defaultSubmitButton={false}
      />
      {showModal && (
        <VoltaModal
          onModalButtonClick={() => onModalButtonClick({ modal, setModal })}
          renderBody={renderModalBody}
          isOpen={modal}
          toggle={toggle}
          title={modalTitle}
          submitModalDisabled={addModalDisabled}
          defaultSubmitButton={defaultSubmitButton}
        />
      )}
    </div>
  );
}

TableContent.defaultProps = {
  columns: [],
  data: [],
  renderFilterContent: () => null,
  renderFilterDefault: true,
  onApplyFilterClicked: () => {},
  showFilterButton: true,
  addButton: false,
  addButtonText: '',
  renderModalBody: () => null,
  onModalButtonClick: () => {},
  paginationSize: 5,
  onPaginationChange: () => {},
  totalSize: -1,
  modalTitle: '',
  inputPlaceholder: 'ID Pengguna',
  defaultSubmitButton: false,
  onAddButtonClicked: null,
  showModal: true,
  keyField: 'no',
  loading: false,
  showApplyFilterButton: false,
  applyButtonTitle: 'Terapkan Filter',
  limit: 8,
  onAfterTextTyped: () => {},
  onTextTyping: () => {},
  page: 1,
  onTextKeyDownTyping: () => {},
  textSearch: '',
  onResetFilterClicked: () => {},
  showResetSearchButton: false,
  onResetSearchClicked: () => {},
  useSystemParameterLimit: true,
  addModalDisabled: false,
  textSearchVisibility: true,
  showPagination: true,
  isDebounceSearch: false,
  additionalContent: () => {},
  showFilterIndicator: false,
};

TableContent.propTypes = {
  columns: PropTypes.arrayOf(Object),
  data: PropTypes.arrayOf(Object),
  renderFilterContent: PropTypes.func,
  renderFilterDefault: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  addButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  addButtonIcon: PropTypes.node,
  renderModalBody: PropTypes.func,
  onModalButtonClick: PropTypes.func,
  paginationSize: PropTypes.number,
  onPaginationChange: PropTypes.func,
  totalSize: PropTypes.number,
  modalTitle: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  defaultSubmitButton: PropTypes.bool,
  onAddButtonClicked: PropTypes.func,
  showModal: PropTypes.bool,
  keyField: PropTypes.string,
  loading: PropTypes.bool,
  onApplyFilterClicked: PropTypes.func,
  showApplyFilterButton: PropTypes.bool,
  applyButtonTitle: PropTypes.string,
  limit: PropTypes.number,
  onAfterTextTyped: PropTypes.func,
  onTextTyping: PropTypes.func,
  page: PropTypes.number,
  onTextKeyDownTyping: PropTypes.func,
  textSearch: PropTypes.string,
  onResetFilterClicked: PropTypes.func,
  showResetSearchButton: PropTypes.bool,
  onResetSearchClicked: PropTypes.func,
  useSystemParameterLimit: PropTypes.bool,
  addModalDisabled: PropTypes.bool,
  textSearchVisibility: PropTypes.bool,
  showPagination: PropTypes.bool,
  isDebounceSearch: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  onDownloadClicked: PropTypes.func,
  isDownloadLoading: PropTypes.bool,
  additionalContent: PropTypes.func,
  showFilterIndicator: PropTypes.bool,
};
