import React, { useEffect, useState } from 'react';
import { VoltaCollapseNavLink, VoltaNavLink } from '@stb/component/cards';
import { Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from '@stb/assets/svg/component';

export default function SidebarMenu({ item, isOpenCollapse, openMenuHandler }) {
  const [collapse, setCollapse] = useState(isOpenCollapse);
  const toggle = () => setCollapse(!collapse);

  useEffect(() => {
    setCollapse(isOpenCollapse);
  }, [isOpenCollapse]);

  return (
    <React.Fragment>
      <div className="item item-main" onClick={toggle}>
        <span className="main-icon">{item.icon}</span>
        <p className="main-title">{item.value}</p>
        <span className="main-icon-chevron">{collapse ? <ChevronUp /> : <ChevronDown />}</span>
      </div>
      <Collapse className="item-group" isOpen={collapse}>
        {item.children.map((child, index) => {
          return child.children ? (
            <VoltaCollapseNavLink {...child} />
          ) : (
            <VoltaNavLink
              key={index}
              headerName={child.headerName}
              to={child.to}
              onClick={() => {
                openMenuHandler();
              }}
              activeClassName="active"
              className="item item-child"
            >
              <p className="side-title">{child.value}</p>
            </VoltaNavLink>
          );
        })}
      </Collapse>
    </React.Fragment>
  );
}
