export const STATUS_DATA = [
  {
    id: '1',
    label: 'Aktif',
    value: true,
    name: 'optionStatus',
  },
  {
    id: '2',
    label: 'Tidak Aktif',
    value: false,
    name: 'optionStatus',
  },
];

export const STATUS_IOT = [
  {
    id: '1',
    label: 'Online',
    value: true,
    name: 'optionStatus',
  },
  {
    id: '2',
    label: 'Offline',
    value: false,
    name: 'optionStatus',
  },
];

export const STATUS_TOKEN = [
  {
    id: '1',
    label: 'Sudah Terisi',
    value: true,
    name: 'optionStatus',
  },
  {
    id: '2',
    label: 'Hampir Habis',
    value: false,
    name: 'optionStatus',
  },
];

export const LIMIT_TABLE_DATA = 8;

export const FORMAT_TIME = 'DD MMMM YYYY - HH:mm';
export const FORMAT_DATE = 'DD MMMM YYYY';

export const ROLE_ID_AND_NAME_CONVENTION = {
  FACTORY: process.env.REACT_APP_ROLE_FACTORY,
  PERTAMINA: process.env.REACT_APP_ROLE_PERTAMINA,
  PLN: process.env.REACT_APP_ROLE_PLN,
  ARKADIA: process.env.REACT_APP_ROLE_ARKADIA,
};

export const ROLE_NAME_AND_ID_CONVENTION = {
  [parseInt(process.env.REACT_APP_ROLE_TECHNICIAN)]: 'TECHNICIAN',
  [parseInt(process.env.REACT_APP_ROLE_FACTORY)]: 'FACTORY',
  [parseInt(process.env.REACT_APP_ROLE_PERTAMINA)]: 'PERTAMINA',
  [parseInt(process.env.REACT_APP_ROLE_PLN)]: 'PLN',
  [parseInt(process.env.REACT_APP_ROLE_ARKADIA)]: 'ARKADIA',
};

export const SGB_TYPE = {
  REGULAR: 'REGULAR',
  PLN_SPBKLU_INTERCHANGEABLE: 'PLN_SPBKLU_INTERCHANGEABLE',
};
