import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { Cross } from '@stb-svg';
import './styles.scss';

export default function VoltaModal({
  isOpen,
  toggle,
  renderBody,
  onModalButtonClick,
  title,
  titleAlign,
  defaultSubmitButton,
  submitModalDisabled,
  size,
  fullscreen,
  centered,
  unmountOnClose,
}) {
  const modalRef = useRef();
  return (
    <div className="back-modal">
      <Modal
        ref={modalRef}
        backdrop="static"
        isOpen={isOpen}
        toggle={toggle}
        className="volta-modal"
        unmountOnClose={unmountOnClose}
        size={size}
        fullscreen={fullscreen}
        centered={centered}
      >
        <ModalBody>
          <div className="volta-modal-header">
            <h6
              className={
                titleAlign === 'center'
                  ? 'volta-modal-title text-center'
                  : titleAlign === 'left'
                  ? 'volta-modal-title text-left'
                  : 'volta-modal-title'
              }
            >
              {title}
            </h6>
            <button onClick={toggle} className="btn">
              <img width={15} height={15} src={Cross} />
            </button>
          </div>
          <div className="volta-modal-body">
            {renderBody({ toggle, modalRef })}
            {defaultSubmitButton && (
              <button
                type="submit"
                disabled={submitModalDisabled}
                onClick={onModalButtonClick}
                className="btn btn-modal-submit"
              >
                Simpan Perubahan
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

VoltaModal.defaultProps = {
  isOpen: false,
  toggle: () => {},
  renderBody: () => null,
  onModalButtonClick: () => {},
  title: '',
  titleAlign: 'center',
  defaultSubmitButton: true,
  submitModalDisabled: false,
  size: '',
  fullscreen: '',
  centered: false,
  unmountOnClose: false,
};

VoltaModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  renderBody: PropTypes.func,
  onModalButtonClick: PropTypes.func,
  title: PropTypes.string,
  titleAlign: PropTypes.oneOf(['center', 'left']),
  defaultSubmitButton: PropTypes.bool,
  submitModalDisabled: PropTypes.bool,
  size: PropTypes.string,
  fullscreen: PropTypes.string,
  centered: PropTypes.bool,
  unmountOnClose: PropTypes.bool,
};
