import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from '@stb-component-svg';
import './styles.scss';

export default function FormContent({ renderForm, loading, className, style }) {
  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  return (
    <div className={`form-content ${className}`} style={style}>
      {renderForm()}
    </div>
  );
}

FormContent.defaultProps = {
  renderForm: () => null,
  loading: false,
  className: '',
  style: {},
};

FormContent.propTypes = {
  renderForm: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};
