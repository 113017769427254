import React from 'react';

import {
  MenuMaster,
  FileText,
  SmilingFace,
  CameraMonitoring,
  BatteryBox,
  Exit,
  ShoppingCart,
  BatteryLow,
  BillList,
  Notebook,
} from '@stb-svg/component';

export const LIST_MENU_DEFAULT = [
  {
    value: 'Manajemen SGB',
    icon: <MenuMaster />,
    defaultOpen: true,
    children: [
      {
        to: '/group-stb',
        value: 'Grup SGB',
        headerName: 'Grup SGB',
        exact: true,
      },
      {
        to: '/partner-stb',
        value: 'Partner SGB',
        headerName: 'Partner SGB',
        exact: true,
      },
      {
        to: '/stb-management',
        value: 'SGB',
        headerName: 'SGB',
        exact: false,
      },
      {
        to: '/token-sgb',
        value: 'SGB Token',
        headerName: 'SGB Token',
        exact: true,
      },
    ],
  },
  {
    value: 'Manajemen Baterai',
    icon: <BatteryBox />,
    defaultOpen: false,
    children: [
      {
        to: '/batery-management',
        value: 'Baterai',
        headerName: 'Baterai',
        exact: true,
      },
      {
        to: '/group-battery',
        value: 'Master Group Baterai',
        headerName: 'Master Group Baterai',
        exact: true,
      },
    ],
  },
  {
    value: 'Manajemen Produk',
    icon: <ShoppingCart />,
    defaultOpen: false,
    children: [
      {
        to: '/paket-baterai',
        value: 'Master Paket',
        headerName: 'Master Paket',
        exact: true,
      },
      {
        to: '/subscription',
        value: 'Paket Tukar Baterai',
        headerName: 'Paket Tukar Baterai',
        exact: true,
      },
      {
        to: '/product-voucher-packages',
        value: 'Paket Voucher Produk',
        headerName: 'Paket Voucher Produk',
      },
    ],
  },
  {
    value: 'Monitoring Fitur',
    icon: <FileText />,
    defaultOpen: false,
    children: [
      {
        to: '/monitor-battery-list',
        value: 'Tukar Baterai',
        headerName: 'Tukar Baterai',
      },
      {
        to: '/monitor-scb',
        value: 'Sistem Charge Baterai',
        headerName: 'Sistem Charge Baterai',
      },
      {
        to: '/claim-battery',
        value: 'Klaim Baterai Drop',
        headerName: 'Klaim Baterai Drop',
      },
      {
        id: '4',
        to: '/point-management',
        value: 'Poin',
        headerName: 'Poin',
      },
    ],
  },
  {
    value: 'Monitoring Baterai',
    icon: <BatteryLow />,
    defaultOpen: false,
    children: [
      {
        to: '/live-battery-monitoring',
        value: 'Live Lokasi Baterai',
        headerName: 'Live Lokasi Baterai',
        exact: true,
      },
    ],
  },
  {
    value: 'Monitoring SGB',
    icon: <CameraMonitoring />,
    defaultOpen: false,
    children: [
      {
        to: '/door-monitoring',
        value: 'Pintu SGB',
        headerName: 'Daftar Pintu SGB yang Terbuka',
        exact: true,
      },
      {
        to: '/live-sgb-and-battery-monitoring',
        value: 'Live Lokasi SGB',
        headerName: 'Monitoring Lokasi SGB',
        exact: true,
      },
      {
        to: '/live-sgb-and-user-monitoring',
        value: 'SGB & Pengguna',
        headerName: 'Monitoring SGB & Pengguna',
        exact: true,
      },
      {
        to: '/live-motorcycle-color-monitoring',
        value: 'Monitoring Warna Motor',
        headerName: 'Monitoring Warna Motor',
        exact: true,
      },
      {
        to: '/battery-lock-cabinet',
        value: 'Baterai Lock Kabinet',
        headerName: 'Baterai Lock Kabinet',
        exact: true,
      },
    ],
  },
  {
    value: 'Transaksi',
    icon: <BillList />,
    defaultOpen: false,
    children: [
      {
        to: '/package-purchases',
        value: 'Transaksi Pembelian Paket',
        headerName: 'Transaksi Pembelian Paket',
      },
      {
        to: '/scb-package-purchases',
        value: 'Transaksi Sistem Charge Baterai',
        headerName: 'Transaksi Sistem Charge Baterai',
      },
      {
        to: '/booking-package-purchases',
        value: 'Transaksi Booking',
        headerName: 'Transaksi Booking',
      },
      {
        to: '/entrust-package-purchases',
        value: 'Transaksi Tukar Titip',
        headerName: 'Transaksi Tukar Titip',
      },
    ],
  },
  {
    value: 'SGB Mitra',
    icon: <Exit />,
    defaultOpen: false,
    children: [
      {
        to: '/mitra/sgb',
        value: 'SGB Mitra',
        headerName: 'SGB Mitra',
        exact: true,
      },
    ],
  },
  {
    value: 'SGB Kemitraan',
    icon: <Exit />,
    defaultOpen: false,
    children: [
      {
        to: '/kemitraan/battery',
        value: 'Baterai Kemitraan',
        headerName: 'Inventaris Baterai Kemitraan',
        exact: true,
      },
      {
        to: '/kemitraan/balance',
        value: 'Informasi Saldo Kemitraan',
        headerName: 'Informasi Saldo Kemitraan',
        exact: true,
      },
    ],
  },
  {
    value: 'Administrator',
    icon: <FileText />,
    defaultOpen: false,
    children: [
      {
        to: '/audit-trail/aktivitas',
        value: 'Audit Trail',
        headerName: 'Audit Trail',
        exact: false,
      },
      {
        to: '/system-parameter',
        value: 'System Parameter',
        headerName: 'System Parameter',
        exact: true,
      },

      {
        to: '/broadcast-notification',
        value: 'Notifikasi User',
        headerName: 'Notifikasi User',
        exact: true,
      },
    ],
  },
  {
    value: 'Laporan',
    icon: <Notebook />,
    defaultOpen: false,
    children: [
      {
        to: '/report',
        value: 'Laporan',
        headerName: 'Laporan',
        exact: true,
      },
    ],
  },
  {
    value: 'Profile',
    icon: <SmilingFace />,
    children: [
      {
        to: '/change-password',
        value: 'Ganti Kata Sandi',
        headerName: 'Ganti Sandi',
        exact: true,
      },
      {
        to: '/preference',
        value: 'Preference',
        headerName: 'Preference',
        exact: true,
      },
    ],
  },
];

export const LIST_MENU_FACTORY = [
  {
    value: 'Manajemen Baterai',
    icon: <BatteryBox />,
    defaultOpen: true,
    children: [
      {
        to: '/batery-management',
        value: 'Baterai',
        headerName: 'Baterai',
        exact: true,
      },
    ],
  },
  {
    value: 'Profile',
    icon: <SmilingFace />,
    children: [
      {
        to: '/change-password',
        value: 'Ganti Kata Sandi',
        headerName: 'Ganti Sandi',
        exact: true,
      },
      {
        to: '/preference',
        value: 'Preference',
        headerName: 'Preference',
        exact: true,
      },
    ],
  },
];

export const LIST_MENU_FOR_STB_PARTNER = [
  {
    value: 'Monitoring Fitur',
    icon: <FileText />,
    defaultOpen: true,
    children: [
      {
        to: '/monitor-battery-list',
        value: 'Tukar Baterai',
        headerName: 'Tukar Baterai',
      },
    ],
  },
  {
    value: 'Laporan',
    icon: <Notebook />,
    defaultOpen: false,
    children: [
      {
        to: '/report',
        value: 'Laporan',
        headerName: 'Laporan',
        exact: true,
      },
    ],
  },
  {
    value: 'Profile',
    icon: <SmilingFace />,
    children: [
      {
        to: '/change-password',
        value: 'Ganti Kata Sandi',
        headerName: 'Ganti Sandi',
        exact: true,
      },
      {
        to: '/preference',
        value: 'Preference',
        headerName: 'Preference',
        exact: true,
      },
    ],
  },
];

export const LIST_MENU_FOR_PLN = [
  {
    value: 'Manajemen SGB',
    icon: <MenuMaster />,
    defaultOpen: true,
    children: [
      {
        to: '/stb-management',
        value: 'SGB',
        headerName: 'SGB',
        exact: false,
      },
    ],
  },
  {
    value: 'Monitoring Fitur',
    icon: <FileText />,
    defaultOpen: true,
    children: [
      {
        to: '/monitor-battery-list',
        value: 'Tukar Baterai',
        headerName: 'Tukar Baterai',
      },
    ],
  },
  {
    value: 'Laporan',
    icon: <Notebook />,
    defaultOpen: false,
    children: [
      {
        to: '/report',
        value: 'Laporan',
        headerName: 'Laporan',
        exact: true,
      },
    ],
  },
  {
    value: 'Profile',
    icon: <SmilingFace />,
    children: [
      {
        to: '/change-password',
        value: 'Ganti Kata Sandi',
        headerName: 'Ganti Sandi',
        exact: true,
      },
      {
        to: '/preference',
        value: 'Preference',
        headerName: 'Preference',
        exact: true,
      },
    ],
  },
];
