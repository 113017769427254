import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getImageUrl } from '@stb/utils/helper';
import './styles.scss';
import { Zoom } from '@stb/assets/svg';
import { VoltaModal } from '@stb/component/layouts';

const ImageThumbnail = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <button
        className="wrapper-thumbnail btn"
        style={{
          width: props.width,
          height: props.height,
        }}
        onClick={() => setIsModalOpen(true)}
        disabled={!props.src}
      >
        <img className="image-thumbnail" src={getImageUrl(props.src)} />
        {props.src && <img className="icon-zoom" src={Zoom} />}
      </button>
      <VoltaModal
        isOpen={isModalOpen}
        size="lg"
        title={props.title}
        renderBody={() => <img className="w-100 mb-2" src={getImageUrl(props.src)} />}
        defaultSubmitButton={null}
        toggle={() => setIsModalOpen(false)}
      />
    </>
  );
};
ImageThumbnail.defaultProps = {
  title: 'Preview Gambar',
  width: 40,
  height: 40,
};
ImageThumbnail.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default ImageThumbnail;
