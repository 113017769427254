import React, { useEffect, useState } from 'react';
import { Label, Input, FormGroup } from 'reactstrap';
import './styles.scss';
import PropTypes from 'prop-types';

export default function RadioButton({
  name,
  data,
  title,
  defaultSelected,
  onRadioSelected,
  className,
  errorText: errorTextProps,
  disabled,
  direction,
  required,
}) {
  const [selectedRadio, setSelectedRadio] = useState(defaultSelected);
  const [errorText, setErrorText] = useState(errorTextProps);

  function handleChange(e) {
    const { name, value } = e.target;
    setSelectedRadio({
      name: name,
      value: value,
    });
  }

  useEffect(() => {
    onRadioSelected(selectedRadio);
  }, []);

  useEffect(() => {
    onRadioSelected(selectedRadio);
  }, [selectedRadio]);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  /**
   * Input name is derived from the `name` prop if it exists,
   * otherwise it is derived from the first item in the `data` array,
   * if `data` exists. If neither `name` nor `data` exist, `inputName` is set to `null`.
   * @type {string|null}
   */
  const inputName = name || (data ? data[0].name : null);

  return (
    <div className={`sgb-radio ${className}`}>
      <label className="form-label d-block">
        {title}
        {required && <span className="text-danger"> *</span>}
      </label>
      {data.map((item) => (
        <FormGroup check key={item.id} className={`form-check-${direction}`}>
          <Input
            id={`${inputName}-${item.id}`}
            name={inputName}
            type="radio"
            value={item.value}
            onClick={handleChange}
            {...(item.id === defaultSelected?.id ? { defaultChecked: true } : {})}
            disabled={errorText || disabled}
          />{' '}
          <Label check for={`${inputName}-${item.id}`}>
            {item.label}
          </Label>
        </FormGroup>
      ))}
      {errorText && <p className="text-danger text-error mt-2">{errorText}</p>}
    </div>
  );
}

RadioButton.defaultProps = {
  name: null,
  errorText: '',
  disabled: false,
  className: 'form-group',
  direction: 'row',
  required: false,
};

RadioButton.propTypes = {
  name: PropTypes.string,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['column', 'row']),
  required: PropTypes.bool,
};
