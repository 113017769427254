export function getRuleListFromRulesText(field, formValues) {
  const rulesArray = field.rules.split('|').reverse();
  const rules = {};
  rulesArray.forEach((rule) => {
    const ruleSplit = rule.split(':');
    if (ruleSplit[0] === 'required') {
      rules.required = true;
    }
    if (field.type === 'datetime') {
      if (ruleSplit[0] === 'min') {
        switch (ruleSplit[1]) {
          case 'today':
            rules.minDate = new Date();
            break;
          default:
            rules.minDate = new Date(formValues[ruleSplit[1]]);
            break;
        }
      }
    }
  });
  return rules;
}
export function getErrorMessageFromRulesText(rulesText, target, indexOfErrorMessage) {
  const rulesArray = rulesText.split('|');
  return rulesArray.find((ruleText) => ruleText.split(':')[0] === target).split(':')[
    indexOfErrorMessage
  ];
}
