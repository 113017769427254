import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';

export default function InputTextArea({
  className,
  style,
  title,
  id,
  value,
  onValueChange,
  errorText: errorTextProps,
  disabled,
  required,
  maxLength,
  maxLengthCustomWarning,
  placeholder,
  inputClassName,
}) {
  const [errorText, setErrorText] = useState(errorTextProps);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  function onChange(e) {
    const newVal = typeof e === 'string' ? e : e.target.value;
    if (newVal.length > maxLength) {
      if (maxLengthCustomWarning) {
        setErrorText(`${maxLengthCustomWarning(maxLength)}`);
        return;
      }
      setErrorText(`Jumlah maksimal karakter adalah ${maxLength}`);
      return;
    }
    setErrorText('');
    onValueChange(e.target.value);
  }

  return (
    <div className={className} style={style}>
      {title && (
        <label htmlFor={id}>
          {title}
          {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <Input
        value={value}
        className={`${errorText && 'error-input'}${inputClassName ? ' ' + inputClassName : ''}`}
        onChange={onChange}
        type="textarea"
        id={id}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
      />
      {errorText && <p className="text-danger text-error">{errorText}</p>}
    </div>
  );
}

InputTextArea.defaultProps = {
  className: '',
  style: {},
  id: '',
  value: '',
  title: '',
  onValueChange: () => {},
  errorText: '',
  disabled: false,
  required: false,
  maxLength: Infinity,
  maxLengthCustomWarning: null,
  placeholder: null,
  inputClassName: '',
};

InputTextArea.propTypes = {
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  id: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  title: PropTypes.string,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  maxLengthCustomWarning: PropTypes.func,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
};
