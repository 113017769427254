import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export default function InputSelect({
  title,
  id,
  data,
  keyField,
  labelField,
  ariaLabel,
  onItemSelected,
  className,
  selected,
  style,
  disabled,
  errorText: errorTextProps,
  placeholder,
  withPlaceholder,
  required,
}) {
  const [displayedData, setDisplayedData] = useState(data);
  const [errorText, setErrorText] = useState(errorTextProps);
  const selectRef = useRef();

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.selectedIndex = getSelectedIndex();
    }
  }, []);

  useEffect(() => {
    setErrorText(errorTextProps);
  }, [errorTextProps]);

  useEffect(() => {
    if (withPlaceholder) {
      const newdisplayedData = [
        { [keyField]: -1, [labelField]: placeholder, value: null },
        ...data,
      ];
      setDisplayedData(newdisplayedData);
    } else {
      setDisplayedData(data);
    }
  }, [data]);

  function getSelectedIndex() {
    if (!selected) {
      return 0;
    }
    const index = displayedData.findIndex((d) => d[keyField] === selected[keyField]);
    return index;
  }

  function getDisplayedItem(item) {
    if (typeof labelField === 'string') {
      return item[labelField];
    } else if (typeof labelField === 'function') {
      return labelField(item);
    }
  }

  return (
    <div className={className} style={style}>
      {title && (
        <label htmlFor={id} className="form-label">
          {title}
          {required && <span className="text-danger"> *</span>}
        </label>
      )}
      <select
        ref={selectRef}
        className={`form-select ${errorText && 'error-input'}`}
        onChange={(e) => {
          const index = Number(e.target.value);
          const d = displayedData[index];
          onItemSelected(d);
        }}
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        value={getSelectedIndex()}
      >
        {displayedData.map((item, index) => (
          <option
            // selected={isSelected(item, index)}
            key={item[keyField] || index}
            value={index}
          >
            {getDisplayedItem(item)}
          </option>
        ))}
      </select>
      {errorText && <p className="text-danger text-error">{errorText}</p>}
    </div>
  );
}

InputSelect.defaultProps = {
  title: '',
  id: '',
  data: [],
  keyField: 'id',
  labelField: 'label',
  ariaLabel: '',
  onItemSelected: () => {},
  className: 'form-group',
  style: {},
  selected: null,
  disabled: false,
  errorText: '',
  placeholder: '-- Pilih Data -- ',
  withPlaceholder: false,
  required: false,
};

InputSelect.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.arrayOf(Object),
  keyField: PropTypes.string,
  ariaLabel: PropTypes.string,
  onItemSelected: PropTypes.func,
  labelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  style: PropTypes.objectOf(Object),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(Object), PropTypes.number]),
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  withPlaceholder: PropTypes.bool,
  required: PropTypes.bool,
};
