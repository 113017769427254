import axios from 'axios';

const /dev/stb/web = process.env.REACT_APP_/dev/stb/web_NODE_STB;
const /dev/stb/web_GO = process.env.REACT_APP_/dev/stb/web_GO_VOLTA;
const /dev/stb/web_NODE_VOLTA = process.env.REACT_APP_/dev/stb/web_NODE_VOLTA;
const /dev/stb/web_GO_STB = process.env.REACT_APP_/dev/stb/web_GO_STB;
const isDev = process.env.NODE_ENV && process.env.NODE_ENV === 'development';

const api = axios.create({
  baseURL: /dev/stb/web,
});

const apiGo = axios.create({
  baseURL: /dev/stb/web_GO,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

const apiNodeVolta = axios.create({
  baseURL: /dev/stb/web_NODE_VOLTA,
});

const apiGoStb = axios.create({
  baseURL: /dev/stb/web_GO_STB,
});

if (isDev) {
  api.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  apiGo.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  apiNodeVolta.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  apiGoStb.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
}

export { apiGo, apiNodeVolta, api, apiGoStb, /dev/stb/web_GO_STB, /dev/stb/web_GO };

export default api;
