import React, { lazy, Suspense } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

const StbLocation = lazy(() => import('@stb-management-stb/screens/STBLocation'));
const LiveVoltaBatteryMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveVoltaBatteryMonitoring')
);
const LiveBatteryMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveBatteryMonitoring')
);
const LiveSgbAndUserMonitoring = lazy(() =>
  import('@stb-module-live-battery-monitoring/screens/LiveSgbAndUserMonitoring')
);
const HEADER = {
  'sgb-node-apikey': process.env.REACT_APP_API_KEY_SGB_NODE,
};

export default function EmbedNavigation() {
  return (
    <HashRouter>
      <Suspense fallback={<React.Fragment />}>
        <Switch>
          <Route path="/embed/sgb-location">
            <div className="mx-4 my-4">
              <StbLocation showEmbedButton={false} />
            </div>
          </Route>
          <Route path="/embed/volta-battery-location">
            <div className="mx-4 my-4">
              <LiveVoltaBatteryMonitoring apiHeader={HEADER} />
            </div>
          </Route>
          <Route path="/embed/live-battery-monitoring">
            <div className="mx-4 my-4">
              <LiveBatteryMonitoring apiHeader={HEADER} />
            </div>
          </Route>
          <Route path="/embed/live-sgb-and-battery-monitoring/:customerTypeSlug">
            <div className="mx-4 my-4">
              <LiveSgbAndUserMonitoring />
            </div>
          </Route>
          <Redirect from="/" to="/log-in" />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}
