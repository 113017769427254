import { SET_PARAMETER, CLEAR_PARAMETER } from '../extra-constants/system-parameter';

const initialState = {
  listParameters: [],
  action: '',
};

function parameterReducer(state = initialState, action) {
  const { type, payload } = action;
  const actions = {
    [SET_PARAMETER]: () => ({
      ...state,
      listParameters: payload.map((item) => {
        return {
          ...item,
          pValue:
            item.userParameters?.nodes.length > 0
              ? item.userParameters.nodes[0].userParameterValue
              : item.pValue,
        };
      }),
      action: type,
    }),
    [CLEAR_PARAMETER]: () => ({
      ...state,
      listParameters: [],
      action: type,
    }),
  };

  return actions[type] ? actions[type]() : state;
}

export { parameterReducer };
