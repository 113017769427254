import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { CheckList } from '@stb-svg';
import './styles.scss';

export default function InputTableAccess() {
  const MENU_ACCESSES = [
    {
      id: '1',
      menu: 'Menu 1',
      is_create: true,
      is_read: true,
      is_update: false,
      is_delete: true,
    },
    {
      id: '2',
      menu: 'Menu 2',
      is_create: true,
      is_read: true,
      is_update: false,
      is_delete: true,
    },
    {
      id: '3',
      menu: 'Menu 3',
      is_create: true,
      is_read: true,
      is_update: false,
      is_delete: true,
    },
    {
      id: '4',
      menu: 'Menu 4',
      is_create: true,
      is_read: true,
      is_update: false,
      is_delete: true,
    },
  ];
  const columns = [
    {
      dataField: 'menu',
      text: 'Menu',
      align: 'left',
    },
    {
      dataField: 'is_create',
      text: 'Membuat',
      align: 'center',
      headerAlign: 'center',
      formatter,
    },
    {
      dataField: 'is_read',
      text: 'Melihat',
      align: 'center',
      headerAlign: 'center',
      formatter,
    },
    {
      dataField: 'is_update',
      text: 'Memperbaharui',
      align: 'center',
      headerAlign: 'center',
      formatter,
    },
    {
      dataField: 'is_delete',
      text: 'Menghapus',
      align: 'center',
      headerAlign: 'center',
      formatter,
    },
  ];

  function formatter(cell) {
    if (cell) {
      return <img src={CheckList} />;
    }
    return '';
  }

  return (
    <BootstrapTable
      keyField="id"
      rowClasses="table-row"
      bootstrap4
      striped
      headerClasses="table-heading"
      wrapperClasses="menu-access-table"
      data={MENU_ACCESSES}
      columns={columns}
      bordered={false}
    />
  );
}
