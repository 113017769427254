import React from 'react';
import PropTypes from 'prop-types';
import '../InputPagination/styles.scss';
import { ChevronLeft, ChevronRight } from '@stb-svg';

export default function InputCursorPagination({
  page,
  paginationCycle,
  totalPagination,
  onPaginationChange,
  hasNextPage,
  hasPreviousPage,
}) {
  const start = (paginationCycle - 1) * totalPagination;
  const end = start + totalPagination;

  function getPaginationListPage() {
    const result = [];

    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onClickPagination = (nextPage, type) => {
    window.scrollTo({ top: 0, behavior: 'instant' });

    onPaginationChange(nextPage, paginationCycle, type);
  };

  return (
    <div className="row d-flex justify-content-center align-items-center">
      <div className="volta-pagination d-flex justify-content-center">
        {hasPreviousPage && (
          <>
            <a onClick={() => onClickPagination(start, 'prev')}>
              <span className="btn-pagination">
                <img src={ChevronLeft} />
              </span>
            </a>
            <a onClick={() => onClickPagination(1, 'first')}>
              <span className="btn-pagination num">1</span>
            </a>
            <a>
              <span className="btn-pagination num">...</span>
            </a>
          </>
        )}

        {getPaginationListPage().map((i) => (
          <a onClick={() => onClickPagination(i + 1)} key={i}>
            <span className={`btn-pagination num ${i + 1 === page ? 'selected' : ''}`}>
              {i + 1}
            </span>
          </a>
        ))}
        {/* {page !== totalPagination - 1 && totalPagination > paginationSize && (
          <>
            <a>
              <span className="btn-pagination num">...</span>
            </a>
            <a
              onClick={() =>
                onClickPagination(totalPagination - 1, 'last')
              }
            >
              <span className="btn-pagination num">{totalPagination}</span>
            </a>
          </>
        )} */}
        {hasNextPage && (
          <>
            <a>
              <span className="btn-pagination num">...</span>
            </a>
            <a onClick={() => onClickPagination(end + 1, 'next')}>
              <span className="btn-pagination">
                <img src={ChevronRight} />
              </span>
            </a>
          </>
        )}
      </div>
    </div>
  );
}

InputCursorPagination.defaultProps = {
  page: 1,
  paginationCycle: 1,
  totalPagination: -1,
  onPaginationChange: (nextPage, lastPaginationCycle, nextOrPrev) => {},
  hasNextPage: false,
  hasPreviousPage: false,
};

InputCursorPagination.propTypes = {
  page: PropTypes.number,
  paginationCycle: PropTypes.number,
  totalPagination: PropTypes.number,
  onPaginationChange: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
};
