import { LIST_MENU_DEFAULT } from '@stb-component-layout/Sidebar/data';

const LIST_PATHS = ((data) => {
  const paths = {};
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    for (let j = 0; j < d.children.length; j++) {
      const child = d.children[j];
      paths[child.to] = {
        name: child.headerName,
        exact: child.exact,
      };
    }
  }
  return paths;
})(LIST_MENU_DEFAULT);

export default LIST_PATHS;
