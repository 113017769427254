import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAxios, usePrevious } from './index';
import { SET_PROVINCE } from '@stb-redux/extra-constants/province';
import { SET_CITIES, SET_CITIES_BY_PROVINCE } from '@stb-redux/extra-constants/city';

export const useProvinces = () => {
  const [graphqlProvince, provinceLoading, provinceStatus, provinceResponse] = useAxios();
  const provinces = useSelector((state) => state.province.data);
  const [provincesData, setProvincesData] = useState([]);
  const prevProvinceStatus = usePrevious(provinceStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (provinces.length === 0) {
      graphqlProvince({
        id: 'getProvinces',
      });
      return;
    }
    setProvincesData(provinces);
  }, []);

  useEffect(() => {
    if (prevProvinceStatus !== undefined && prevProvinceStatus !== provinceStatus) {
      switch (provinceStatus) {
        case 1:
          dispatch({
            type: SET_PROVINCE,
            payload: provinceResponse.data.provinces.nodes,
          });
          setProvincesData(provinceResponse.data.provinces.nodes);
          break;
        default:
      }
    }
  }, [prevProvinceStatus, provinceStatus]);

  return [provincesData, provinceLoading, provinceStatus];
};

export const useCity = (provinceIdProps = null) => {
  const [graphqlcity, cityLoading, cityStatus, cityResponse] = useAxios();
  const cities = useSelector((state) => state.city.data);
  const citiesByProvince = useSelector((state) => state.city.dataByProvince);
  const [citiesData, setCityData] = useState([]);
  const [provinceId, setProvinceId] = useState(provinceIdProps);
  const prevCityStatus = usePrevious(cityStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (provinceId === null) {
      if (cities.length === 0) {
        graphqlcity({
          id: 'getCities',
          var: {
            cityCondition: {
              provinceId: 1,
            },
          },
        });
        return;
      }
      setCityData(cities);
    } else {
      provinceFetch(provinceId);
    }
  }, []);

  useEffect(() => {
    if (prevCityStatus !== undefined && prevCityStatus !== cityStatus) {
      switch (cityStatus) {
        case 1:
          if (provinceId === null) {
            dispatch({
              type: SET_CITIES,
              payload: cityResponse.data.cities.nodes,
            });
          } else {
            dispatch({
              type: SET_CITIES_BY_PROVINCE,
              payload: { provinceId, data: cityResponse.data.cities.nodes },
            });
          }
          setCityData(cityResponse.data.cities.nodes);
          break;
        default:
      }
    }
  }, [cityLoading, cityStatus]);

  function provinceFetch(province) {
    const c = citiesByProvince[province];
    if (!c) {
      graphqlcity({
        id: 'getCities',
        var: {
          cityCondition: {
            provinceId: Number(province),
          },
        },
      });
      return;
    }
    setCityData(c);
  }

  function onProvinceChange(province) {
    provinceFetch(province);
    setProvinceId(province);
  }

  return [onProvinceChange, citiesData, cityLoading, cityStatus];
};
