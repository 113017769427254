import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import VoltaNavLink from '../VoltaNavLink';

function VoltaCollapseNavLink({ value, children }) {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <div onClick={() => setExpand(!expand)} className="d-flex item align-items-center">
        <p className="side-title">{value}</p>
      </div>

      <Collapse isOpen={expand}>
        {children.map((child) => (
          <VoltaNavLink
            key={child.id}
            headerName={child.headerName}
            to={child.to}
            activeClassName="active"
            className="d-flex item align-items-center"
          >
            <p className="side-title">{child.value}</p>
          </VoltaNavLink>
        ))}
      </Collapse>
    </>
  );
}

VoltaCollapseNavLink.defaultProps = {
  value: '',
  children: [],
};

VoltaCollapseNavLink.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.array,
};

export default VoltaCollapseNavLink;
