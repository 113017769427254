import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.scss';
import { Col, Row } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FORMAT_DATE, FORMAT_TIME } from '@stb/utils/constants';
import { range } from '@stb-utils/helper';
import {
  Airplay,
  Bolt,
  Coins,
  HeartMedical,
  Hourglass,
  MapMarkerAlt,
  Power,
  Robot,
  SignalHigh,
  SignalLow,
  SignalMedium,
  SpaceKey,
  TachometerFast,
  TemperatureEmpty,
} from '@stb/assets/svg';
import { useAxios4 } from '@stb/utils/hooks';
import { Loading } from '@stb/component/svg';

function createGradient(ctx, area) {
  const colorStart = '#EA3C3C';
  const colorEnd = '#3FD84E';

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BatteryDetail({ fetchBatteryDetail, batteryInfo, isBatteryLoading, exludeInfo = [] }) {
  if (isBatteryLoading) {
    return (
      <div className="d-flex justify-content-centern align-items-center">
        <Loading />
      </div>
    );
  }

  if (!batteryInfo) {
    return (
      <div className="d-flex justify-content-centern align-items-center">
        <p className="red">Data Empty</p>
      </div>
    );
  }
  const [batteryState, batteryStateLoading] = useAxios4('/device/battery/runoff', 'post', true);

  function getVoltasiData(cellJSON) {
    let d = [];
    try {
      if (cellJSON) {
        const dataJSON = typeof cellJSON === 'string' ? JSON.parse(cellJSON) : cellJSON;
        Object.entries(dataJSON).forEach((r) => {
          const value = r[1];
          const n = Number(value);
          if (n) {
            d.push(n);
          } else {
            d.push(0);
          }
        });
      }
    } catch (_err) {
      // TODO: handle error
    }

    return d;
  }
  function getBatteryData(d, unit) {
    return d ? `${d} ${unit}` : '-';
  }

  function getLastPosition(voltaBatteries, stbBatteries) {
    const mergedArray = voltaBatteries.concat(stbBatteries);
    let latestPos = moment('1900-01-01');
    let currentData = null;

    mergedArray.forEach((item) => {
      const cretedDate = moment(item.createdDate);
      if (latestPos.isBefore(cretedDate)) {
        latestPos = cretedDate;
        currentData = item;
      }
    });
    if (currentData) {
      if (currentData.stb) {
        return [null, currentData.stb.name];
      } else if (currentData.chassisNumber) {
        return [currentData.chassisNumber, null];
      }
    }
    return [null, null];
  }

  function showBatteryStatus(status) {
    if (status === 'ON') {
      return <span className="green-2 bold font-16">On</span>;
    } else if (status === 'OFF') {
      return <span className="red bold font-16">Off</span>;
    } else if (status === 'ON_UI' || status === 'OFF_UI') {
      return <img src={Hourglass} className="icon-hourglass opacity-50" width={20} />;
    } else {
      return <span className="bold font-16">?</span>;
    }
  }

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    const newChartData = {
      labels: range(batteryInfo.totalCell).map((i) => i + 1 + ''),
      datasets: [
        {
          label: '',
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
          data: getVoltasiData(batteryInfo.cellJSON),
          barPercentage: 0.4,
        },
      ],
    };
    setChartData(newChartData);
  }, [chartRef]);

  const lastPosition = getLastPosition(
    batteryInfo.voltaBatteries.nodes,
    batteryInfo.stbBatteriesByBatteryCode.nodes
  );

  function onHandleChange(status) {
    const isConfirm = confirm(
      status === 'ON'
        ? `Apakah anda ingin mematikan baterai ${batteryInfo.code} ?`
        : `Apakah anda ingin mengaktifkan baterai ${batteryInfo.code} ?`
    );
    if (!batteryStateLoading && isConfirm && (status === 'ON' || status === 'OFF')) {
      try {
        batteryState([
          {
            id: 0,
            description: 'New…',
            deviceId: batteryInfo.id,
            type: 'custom',
            textChannel: false,
            idAtt: '01308001',
            value: status === 'ON' ? 2 : 3,
            devId: batteryInfo.code,
            txnNo: '',
          },
        ]);
        alert('Berhasil mengirim permintaan perubahan status baterai');
        fetchBatteryDetail();
      } catch (error) {
        alert('Gagal mengirim permintaan perubahan status baterai');
      }
    } else if (isConfirm) {
      alert('Gagal mengirim permintaan perubahan status baterai');
    }
  }

  const batteryInfoItems = [
    {
      key: 'capacity',
      icon: SpaceKey,
      label: 'Kapasitas',
      value: getBatteryData(batteryInfo.kapasitasAh / 100, 'Ah'),
    },
    {
      key: 'temperature',
      icon: TemperatureEmpty,
      label: 'Temperatur',
      value: getBatteryData(batteryInfo.suhu, '°'),
    },
    {
      key: 'batteryStatus',
      icon: Robot,
      label: 'Status',
      value: showBatteryStatus(batteryInfo.batteryStatus.name),
    },
    {
      key: 'powerSwitch',
      icon: Power,
      label: (
        <div className="d-flex">
          <span
            style={{
              marginTop: 1,
            }}
          >
            Off/On
          </span>
          <div className="form-switch ms-4">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              role="switch"
              id="customSwitch"
              onChange={() => onHandleChange(batteryInfo.batteryStatus.name)}
              checked={
                batteryInfo.batteryStatus.name === 'ON' ||
                batteryInfo.batteryStatus.name === 'ON_UI'
                  ? true
                  : false
              }
              disabled={
                batteryInfo.batteryStatus.name === 'ON' || batteryInfo.batteryStatus.name === 'OFF'
                  ? false
                  : true
              }
            />
          </div>
        </div>
      ),
      value: '',
    },
    {
      key: 'gsm',
      icon: Airplay,
      label: 'GSM',
      value: getBatteryData(batteryInfo.gsm, ''),
    },
    {
      key: 'signal',
      icon:
        batteryInfo.kekuatanSinyal > 20
          ? SignalHigh
          : batteryInfo.kekuatanSinyal > 9
          ? SignalMedium
          : SignalLow,
      label: 'Kekuatan Sinyal',
      value: getBatteryData(batteryInfo.kekuatanSinyal, 'ASU'),
    },
    {
      key: 'speed',
      icon: TachometerFast,
      label: 'Kecepatan',
      value: `${batteryInfo.speed || 0} Km/Jam`,
    },
    {
      key: 'stateOfHealth',
      icon: HeartMedical,
      label: 'State of Health',
      value: getBatteryData(batteryInfo.stateOfHealth, ''),
    },
    {
      key: 'stateOfCharge',
      icon: Coins,
      label: 'State of Charge',
      value: getBatteryData(batteryInfo.percentCapacity, '%'),
    },
  ];

  return (
    <div className="battery-detail d-flex flex-column mb-4">
      <Row className="battery-detail-body">
        <Col sm={12}>
          <div className="d-flex mb-4 align-items-center flex-wrap">
            <div className="order-1 order-md-2 success-text me-1">
              <img className="icon me-2" src={Bolt} />
              {getBatteryData(batteryInfo.percentCapacity, '%')}
            </div>
            <div className="order-2 order-md-3 failed-text">
              <img className="icon me-2" src={MapMarkerAlt} />
              <a
                className="bold black"
                href={`https://maps.google.com/?q=${batteryInfo.latitude},${batteryInfo.longitude}`}
                target="_blank"
                rel="noreferrer"
              >{`${batteryInfo.latitude},${batteryInfo.longitude}`}</a>
            </div>
            <h4
              className="order-3 order-md-1 font-16 mt-2 me-4"
              style={{
                fontWeight: 600,
              }}
            >
              {batteryInfo.code}
            </h4>
          </div>
          <div className="box-wrapper">
            {batteryInfoItems
              .filter((i) => !exludeInfo.includes(i.key))
              .map((element, key) => (
                <div className="item" key={key}>
                  <div className="icon">
                    <img src={element.icon} alt="Kapasitas" />
                  </div>
                  <p className="black bold font-16 mt-3 mb-1">{element.value}</p>
                  <p className="grey mb-0">{element.label}</p>
                </div>
              ))}
          </div>
        </Col>
        <Col lg={5} className="mt-4">
          <div className="item">
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Total Voltage</span>
              <span className="col-7 black bold mb-2">
                : {getBatteryData(batteryInfo.totalVoltage / 100, 'V')}
              </span>
            </div>
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Total Arus</span>
              <span className="col-7 black bold mb-2">
                : {getBatteryData(batteryInfo.totalCurrent, 'A')}
              </span>
            </div>
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Total Charge</span>
              <span className="col-7 black bold mb-2">
                : {getBatteryData(batteryInfo.totalCharge, 'Ah')}
              </span>
            </div>
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Total Discharge</span>
              <span className="col-7 black bold mb-2">
                : {getBatteryData(batteryInfo.totalDischarge, 'Menit')}
              </span>
            </div>
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Status Abnormal Baterai</span>
              <span className="col-7 black bold mb-2">
                : {getBatteryData(batteryInfo.alarm, '')}
              </span>
            </div>
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">No. Rangka Motor</span>
              <span className="col-7 black bold mb-2">: {getBatteryData(lastPosition[0], '')}</span>
            </div>
            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Nama SGB</span>
              <span className="col-7 black bold mb-2">: {getBatteryData(lastPosition[1], '')}</span>
            </div>

            <div className="d-flex flex-row font-12">
              <span className="col-5 grey mb-2">Update Terakhir</span>
              <span className="col-7 black bold">
                {': '}
                {batteryInfo.modifiedDate
                  ? moment(batteryInfo.modifiedDate).add(7, 'h').format(FORMAT_TIME)
                  : '-'}
              </span>
            </div>

            {!batteryInfo.isActive && (
              <>
                <div className="d-flex flex-row font-12">
                  <span className="col-5 grey mb-2">Status</span>
                  <span className="col-7 black bold mb-2">
                    :{' '}
                    {getBatteryData(
                      batteryInfo.isActive ? 'Aktif - Whitelist' : 'Tidak aktif - Blacklist',
                      ''
                    )}
                  </span>
                </div>
                <div className="d-flex flex-row font-12">
                  <span className="col-5 grey mb-2">Alasan</span>
                  <span className="col-7 black bold mb-2">
                    : {getBatteryData(batteryInfo.note, '')}
                  </span>
                </div>
                <div className="d-flex flex-row font-12">
                  <span className="col-5 grey mb-2">Diblacklist oleh</span>
                  <span className="col-7 black bold mb-2">
                    : {getBatteryData(batteryInfo.modifiedBy, '')}
                  </span>
                </div>
                <div className="d-flex flex-row font-12">
                  <span className="col-5 grey mb-2">Tanggal Blacklist</span>
                  <span className="col-7 black bold mb-2">
                    : {getBatteryData(moment(batteryInfo.modifiedDate).format(FORMAT_DATE), '')}
                  </span>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col lg={7} className="mt-4">
          <div className="item">
            <Bar
              width={400}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  label: {
                    display: false,
                  },
                },
              }}
              data={chartData}
              className="mt-4"
              ref={chartRef}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

BatteryDetail.propTypes = {
  fetchBatteryDetail: PropTypes.func.isRequired,
  batteryInfo: PropTypes.object,
  isBatteryLoading: PropTypes.bool,
  exludeInfo: PropTypes.array,
};

export default BatteryDetail;
